import roller from 'common/module/ui/roller';

import applicationBootstrap from 'website/bootstrap';
import { socialActionsBootstrap } from 'website/bootstrap/socialActionsBootstrap';
import titleActions from 'website/containers/entity/titleActions';
import comments from 'website/containers/generic/comments';
import moviepageShowtimes from 'website/containers/showtimes/moviepage';
// autocomplete Localisation
import initializeLocationSearch from 'website/containers/showtimes/searchLocationAutocomplete';
import userAddToCollection from 'website/containers/user-action/userAddToCollection';
import userRating from 'website/containers/user-action/userRating';
import userReviewLink from 'website/containers/user-action/userReviewLink';
import userWantToSee from 'website/containers/user-action/userWantToSee';
import affinityBadge from 'website/containers/user/affinityBadge';
import affinitySuggestions from 'website/containers/user/affinitySuggestions';
import reviewsSocial from 'website/containers/user/reviewsSocial';
import theaterFavorites from 'website/containers/user/theaterFavorites';
import userFriendsRating from 'website/containers/user/userFriendsRating';
// adgage
import loadAdgage from 'website/module/advertisement/adgage';
// outbrain
import loadOutbrain from 'website/module/advertisement/outbrain';
// taboola
import loadTaboola from 'website/module/advertisement/taboola';
import loadAnchorPlus from 'website/module/anchor/anchor-plus';
// import player
import initPlayer from 'website/module/player';
import initPlayerExport from 'website/module/player/export';
import { changeShowtimeLink } from 'website/module/showtimes/showtimeRedirect';
import validateShowtimeTime from 'website/module/showtimes/showtimeSchedule';
import asyncGallery from 'website/module/ui/async-gallery';
import castFetch from 'website/module/ui/cast-fetch';
import dropdownLinks from 'website/module/ui/dropdown-custom';
// faceBookRCWidget
import facebookWidgets from 'website/module/ui/facebookWidgets';
// import share
import initMoreTriggerOverlay from 'website/module/ui/more-trigger-overlay';
import shotsFetch from 'website/module/ui/shots-fetch';
import thirdNav from 'website/module/ui/third-nav';
import tooltip from 'website/module/ui/tooltip-theater-amenities';
import initUserLocalization from 'website/module/ui/user-localization';
import loadAccesLibre from 'website/third_party/acces-libre';
import setupExtraTracking from 'website/tracking/googletagmanager';

// bootstrap the application / store
applicationBootstrap().then(() => {
  initMoreTriggerOverlay();
  setupExtraTracking();
  initPlayer();
  initPlayerExport();
  thirdNav();

  // Title actions
  titleActions();

  // Social user Action
  userRating();
  userWantToSee();
  userReviewLink();
  userFriendsRating();
  userAddToCollection();

  // update a showtime link with geolocation cookie
  changeShowtimeLink();

  affinitySuggestions();

  // initialize async galleries if needed
  asyncGallery();

  // dropdownLinks
  dropdownLinks();

  // roller
  roller();

  // reviews social action
  reviewsSocial();

  // initializeLocationSearch
  initializeLocationSearch();

  // disqus comments init
  comments();

  // fb rc widget init
  facebookWidgets();

  // outbrain init
  loadOutbrain();

  loadAdgage();

  loadTaboola();

  socialActionsBootstrap().then(() => {
    // to avoid having a display skip.
    // the macaron is loaded after the social request
    affinityBadge();
  });

  // get showtimes for favorite theaters
  theaterFavorites();

  initUserLocalization();

  validateShowtimeTime();

  moviepageShowtimes();

  shotsFetch();

  castFetch();

  loadAnchorPlus();

  loadAccesLibre();

  // Tooltip
  tooltip();
});
